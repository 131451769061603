<template>
  <div id="containerId" class="container" v-if="myDataObj">
    <!-- 导航条容器 -->
    <!-- <div class="navContainer">
      <div class="navTitle">预警数据</div>
    </div> -->
    <!-- 基本信息容器 -->
    <BaseInfoContainer
      :myDataObj="myDataObj"
      :isHiddenBtn=true
      :isExport="isExport"
    />
    <div
      class="itemResultContainer"
      v-if="myDataObj && myDataObj.dimensionAnswerResultObj"
    >
      <div v-for="(item, index) in myDataObj.dimensionAnswerResultObj" :key="index">
        <TitleBar :title="item.title + '    ' + item.score + '    ' + item.tag" :isWarn="item.isWarn"/>
        <div class="itemBox">
          <ContentBox :content="item.explain" />
        </div>
        <el-table :data="item.dimensionList" border style="width: 100%">
          <el-table-column prop="orderNum" align="center" label="选项" width="180">
          </el-table-column>
          <el-table-column prop="topicName" align="center" label="题目" >
          </el-table-column>
          <el-table-column prop="topicOptionText" align="center" label="答项">
          </el-table-column>
          <el-table-column prop="optionScore" align="center" label="分数" width="180"> </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import { mixins } from "../../utils/mixins";
import { getWarnReportData } from "../../api/report";
export default {
  mixins: [mixins],
  name: "WarnReport",
  data() {
    return {
      myDataObj: undefined, //个案网络请求回来的数据
    };
  },
  methods: {
    //个案数据网络请求
    ws_geCaseReportDataNetWorkAction() {
      var that = this;
      getWarnReportData(that.queryParams).then(
        (res) => {
          //成功
          if (res.code == 200) {
            that.myDataObj = res.data;
          }
        }
      );
    },
  },
  mounted() {
    //个案数据网络请求
    this.ws_geCaseReportDataNetWorkAction();
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/commonPage.scss";
</style>
